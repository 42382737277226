import { useEffect, useState } from "react";
import axios from "axios";
import queryString from "query-string";
import Android from "./app/pages/android";
import IOS from "./app/pages/ios";
import ExpireState from "./app/ExpireState";
import Reservation from "./app/Reservation";
import { Spinner } from "./app/Spinner";
import "./App.css";
import ScreenIcon from "./logo-1.png";
import { isAppRunningAsPwa } from "./utils";
import { useQuery } from "react-query";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

function App() {
  const [loading, setLoading] = useState(false);
  const [imageStr, setImageStr] = useState(null);
  const [reservation, setReservation] = useState(null);
  const [isError, setError] = useState(false);

  return (
    <div className="App">
      <header className="App-header">
        {<Reservation loading={loading} setLoading={setLoading} />}
      </header>
    </div>
  );
}

export default App;
