import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Header } from "../Header";
import axios from "axios";
import queryString from "query-string";
import "./styles.css";
import { Spinner } from "../Spinner";
import {
  addCheckInTimeInFormattedDate,
  addCheckOutTimeInFormattedDate,
} from "../../utils";

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
  maxLength,
  inputMode,
}) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
      maxLength={maxLength}
      inputMode={inputMode}
    />
  </div>
);

const Dropdown = ({ label, id, value, onChange, maxLength, data }) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <div className="select">
      <select id={id} onChange={onChange}>
        <option>SELECT A RESERVATION</option>
        {data.map((reservation, i) => {
          return (
            <option key={reservation.id} value={reservation.id}>
              {reservation.reservation_id} (Room {reservation.room_no})
            </option>
          );
        })}
      </select>
    </div>
  </div>
);

const Reservation = (props) => {
  const [reservation, setReservation] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [logout, setLogout] = useState(false);
  const [selectReservation, setSelectReservation] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const [error, setError] = useState(null);
  const [showQrCode, setShowQrCode] = useState(false);
  const [cardLastfour, setCardLastfour] = useState("");
  const [lastname, setLastname] = useState("");
  const [phoneLastfour, setPhoneLastfour] = useState("");
  const [imageStr, setImageStr] = useState(null);
  const { loading, setLoading } = props;
  const [checkInDateTime, setCheckInDateTime] = useState(null);
  const [checkOutDateTime, setCheckOutDateTime] = useState(null);
  const [hotelName, setHotelName] = useState(null);
  const [roomNumber, setRoomNumber] = useState(null);
  const [reservations, setReservations] = useState();

  useEffect(() => {
    const authenticatedLocalStorage = localStorage.getItem("auth");
    if (authenticatedLocalStorage) {
      setAuthenticated(authenticatedLocalStorage);
    }
  }, []);

  const handleAuthenticate = async () => {
    try {
      setLoading(true);
      setError("");

      const data = await getSecretKeyKiosk(secretKey);
      if (secretKey && data.length > 0) {
        setAuthenticated(true);
        localStorage.setItem("auth", true);
        setSecretKey("");
      } else {
        setError("Please use a valid secret key");
      }
    } catch (err) {
      console.log(err);
      setError("Unable to authenticate");
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      setLoading(true);
      setError("");

      const data = await getSecretKeyKiosk(secretKey);
      if (secretKey && data.length > 0) {
        setAuthenticated(false);
        setLogout(false);
        localStorage.removeItem("auth");
        setSecretKey("");
      } else {
        setLogout(false);
      }
    } catch (err) {
      console.log(err);
      setError("Unable to logout");
    } finally {
      setLoading(false);
    }
  };

  const handleFindReservation = async () => {
    try {
      setLoading(true);
      setError("");

      if (lastname && cardLastfour && phoneLastfour) {
        const data = await getReservationKiosk();
        console.log(data);
        if (data.length === 1) {
          const qrImage = await getKeyImageKiosk(data[0].id);
          if (qrImage && qrImage.room_qr_image) {
            setImageStr("data:image/png;base64, " + qrImage.room_qr_image);
          }

          setCheckInDateTime(
            addCheckInTimeInFormattedDate(qrImage.checkin_date)
          );
          setCheckOutDateTime(
            addCheckOutTimeInFormattedDate(qrImage.checkout_date)
          );

          setHotelName(qrImage.property_name);
          setRoomNumber(qrImage.room_no);

          setReservation(data[0].id);
          setShowQrCode(true);

          setTimeout(() => {
            window.addEventListener("afterprint", function (event) {
              setLastname("");
              setCardLastfour("");
              setPhoneLastfour("");
              setReservation(null);
              setShowQrCode(false);
              setSelectReservation(false);
              setError("");
            });
            window.onafterprint = function (event) {
              setLastname("");
              setCardLastfour("");
              setPhoneLastfour("");
              setReservation(null);
              setShowQrCode(false);
              setSelectReservation(false);
              setError("");
            };

            window.print();
          }, 1000);

          // go to find reservations after x seconds
          setTimeout(() => {
            if (reservation && setShowQrCode) {
              setLastname("");
              setCardLastfour("");
              setPhoneLastfour("");
              setReservation(null);
              setShowQrCode(false);
              setSelectReservation(false);
              setError("");
            }
          }, 10000);
        } else if (data.length >= 1) {
          setReservations(data);
          setSelectReservation(true);
          // go to find reservations after x seconds
          setTimeout(() => {
            if (!reservation) {
              setLastname("");
              setCardLastfour("");
              setPhoneLastfour("");
              setReservation(null);
              setReservations();
              setShowQrCode(false);
              setSelectReservation(false);
              setError("");
            }
          }, 30000);
        } else {
          setError("Reservation not found or key expired");
        }
      } else {
        setError("Please fill all required fields");
      }
    } catch (err) {
      console.log(err);
      setError("Key Expired. Please contact the front desk");
    } finally {
      setLoading(false);
    }
  };

  const handleSelectReservation = async (reservationId: string) => {
    try {
      setLoading(true);
      setError("");
      const qrImage = await getKeyImageKiosk(reservationId);
      if (qrImage && qrImage.room_qr_image) {
        setImageStr("data:image/png;base64, " + qrImage.room_qr_image);
      }

      setCheckInDateTime(addCheckInTimeInFormattedDate(qrImage.checkin_date));
      setCheckOutDateTime(
        addCheckOutTimeInFormattedDate(qrImage.checkout_date)
      );

      setHotelName(qrImage.property_name);
      setRoomNumber(qrImage.room_no);

      setReservation(reservationId);
      setShowQrCode(true);

      setTimeout(() => {
        window.addEventListener("afterprint", function (event) {
          setLastname("");
          setCardLastfour("");
          setPhoneLastfour("");
          setReservation(null);
          setReservations();
          setShowQrCode(false);
          setSelectReservation(false);
          setError("");
        });
        window.onafterprint = function (event) {
          setLastname("");
          setCardLastfour("");
          setPhoneLastfour("");
          setReservation(null);
          setReservations();
          setShowQrCode(false);
          setSelectReservation(false);
          setError("");
        };

        window.print();
      }, 1000);

      // go to find reservations after x seconds
      setTimeout(() => {
        if (reservation) {
          setLastname("");
          setCardLastfour("");
          setPhoneLastfour("");
          setReservation(null);
          setReservations();
          setShowQrCode(false);
          setSelectReservation(false);
          setError("");
        }
      }, 10000);
    } catch (err) {
      console.log(err);
      setError("Key Expired. Please contact the front desk");
      setTimeout(() => {
        setLastname("");
        setCardLastfour("");
        setPhoneLastfour("");
        setReservation(null);
        setReservations();
        setShowQrCode(false);
        setSelectReservation(false);
        setError("");
      }, 10000);
    } finally {
      setLoading(false);
    }
  };

  async function getSecretKeyKiosk(key: string) {
    try {
      const property = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}reservationKey/getSecretKeyKiosk?secretKey=${key}`,
        headers: {
          Accept: "application/ecmascript",
        },
      });

      return property.data;
    } catch (error) {
      console.log("Error while getting a key:", error);
      throw error;
    }
  }

  async function getReservationKiosk() {
    try {
      const reservation = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}reservationKey/getReservationKiosk?lastname=${lastname}&cardLastfour=${cardLastfour}&phoneLastfour=${phoneLastfour}`,
        headers: {
          Accept: "application/ecmascript",
        },
      });

      return reservation.data;
    } catch (error) {
      console.log("Error while getting a reservation:", error);
      throw error;
    }
  }

  async function getKeyImageKiosk(reservationId) {
    try {
      const image = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}reservationKey/getRoomKeyImageKiosk?reservationId=${reservationId}`,
        headers: {
          Accept: "application/ecmascript",
        },
      });

      return image.data;
    } catch (error) {
      console.log("Error while getting QR image:", error);
      throw error;
    }
  }

  const handleBack = async () => {
    try {
      setLastname("");
      setCardLastfour("");
      setPhoneLastfour("");
      setReservation(null);
      setShowQrCode(false);
      setSelectReservation(false);
      setError("");
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  return (
    <React.Fragment>
      {loading && <Spinner />}
      <div className="app-wrap">
        <Header />

        {!reservation && !authenticated ? (
          <div className="reservation-wrap">
            <div className="field-container">
              <Field
                label="Secret Key"
                id="secretKey"
                type="password"
                placeholder="Secret key"
                required
                autoComplete="off"
                value={secretKey}
                onChange={(e) => {
                  setSecretKey(e.target.value);
                }}
              />
            </div>
            {error ? <div className="error-container">{error}</div> : null}

            <div className="btn-container">
              <button
                className="btn"
                onClick={() => {
                  handleAuthenticate();
                }}
              >
                Authenticate
              </button>
            </div>
          </div>
        ) : null}

        {logout && authenticated ? (
          <div className="reservation-wrap">
            <div className="field-container">
              <Field
                label="Secret Key"
                id="secretKey"
                type="password"
                placeholder="Secret key"
                required
                autoComplete="off"
                value={secretKey}
                onChange={(e) => {
                  setSecretKey(e.target.value);
                }}
              />
            </div>
            {error ? <div className="error-container">{error}</div> : null}

            <div className="btn-container">
              <button
                className="btn"
                onClick={() => {
                  handleLogout();
                }}
              >
                LOGOUT
              </button>
            </div>

            <div className="btn-container">
              <button
                className="btn-cancel"
                onClick={() => {
                  setLogout(false);
                }}
              >
                FIND RESERVATION
              </button>
            </div>
          </div>
        ) : null}

        {!reservation && !selectReservation && authenticated && !logout ? (
          <div className="reservation-wrap">
            <span className="title-reservation">PRINT YOUR KEY</span>
            <div className="field-container">
              <Field
                label="Last Name"
                id="lastname"
                type="text"
                placeholder="Last name"
                required
                autoComplete="off"
                value={lastname}
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
                inputMode="text"
              />
            </div>

            <div className="field-container">
              <Field
                label="Credit Card"
                id="cardLastfour"
                type="password"
                placeholder="Last four digits"
                required
                autoComplete="off"
                maxLength="4"
                value={cardLastfour}
                onChange={(e) => {
                  setCardLastfour(e.target.value);
                }}
                inputMode="numeric"
              />
            </div>
            <div className="field-container">
              <Field
                label="Phone"
                id="phoneLastfour"
                type="password"
                placeholder="Last four digits"
                required
                autoComplete="off"
                maxLength="4"
                value={phoneLastfour}
                onChange={(e) => {
                  setPhoneLastfour(e.target.value);
                }}
                inputMode="numeric"
              />
            </div>
            {error ? <div className="error-container">{error}</div> : null}

            <div className="btn-container">
              <button
                className="btn"
                onClick={() => {
                  handleFindReservation();
                }}
              >
                FIND RESERVATION
              </button>
            </div>
            <div className="btn-container">
              <button
                className="btn-cancel"
                onClick={() => {
                  setSecretKey("");
                  setCardLastfour("");
                  setPhoneLastfour("");
                  setLogout(true);
                }}
              >
                LOGOUT
              </button>
            </div>
          </div>
        ) : null}

        {!reservation && reservations && selectReservation && authenticated ? (
          <div className="reservation-wrap">
            <div className="field-container">
              <Dropdown
                label="Reservations"
                id="reservations"
                onChange={(e) => {
                  console.log(e.target.value);
                  handleSelectReservation(e.target.value);
                }}
                data={reservations && reservations}
              />
            </div>

            {error ? <div className="error-container">{error}</div> : null}

            {error ? (
              <div className="btn-container">
                <button
                  className="btn"
                  onClick={() => {
                    handleBack();
                  }}
                >
                  BACK
                </button>
              </div>
            ) : null}
          </div>
        ) : null}

        {reservation && showQrCode ? (
          <React.Fragment>
            <div className="qr-code-wrap">
              <img src={imageStr} alt="" />
            </div>
            <div className="hotel-details-wrap">
              <div className="hotel-detail">
                <label className="title">Hotel</label>
                <span className="about-hotel">{hotelName}</span>
              </div>
              <div className="room-details">
                <label className="title">room number</label>
                {process.env.REACT_APP_ENV === "dev"
                  ? "117 - Accessible"
                  : roomNumber}{" "}
              </div>
              <div className="qr-validity">
                <label className="title">QR Code Validity</label>
                <span className="about-hotel">
                  {checkInDateTime} - {checkOutDateTime}
                </span>
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default Reservation;
