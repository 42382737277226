import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./styles.css";
import { Carousel } from "react-responsive-carousel";
import ScreenIcon from "../../logo-1.png";
import { ShareIcon } from "../ShareIcon";

export default class DemoCarousel extends Component {
  render() {
    return (
      <Carousel>
        <div class="slide-text" key="slide1" style={{ padding: 5, height: 65 }}>
          1. Tap on the share icon
          <br />
          2. Scroll down to click on “Add to Home Screen”
        </div>
      </Carousel>
    );
  }
}
